<template>
    <v-container class="py-8 h-100 page-container">
        <!-- Word Cloud and Result Columns -->
        <v-row>
            <!-- Word Cloud Column (60%) -->
            <v-col cols="12" md="7" class="d-flex">
                <WordCloud :words="wordList" :selectedWord="selectedWord" @word-clicked="handleWordClick" />
            </v-col>

            <!-- Result Column (40%) -->
            <v-col cols="12" md="5" class="d-flex" style="height: 100%; justify-content: center; align-items: center;">
                <WishList :wishes="selectedWishes" />
            </v-col>
        </v-row>

        <!-- Title -->
        <v-row class="page-title">
            <v-col>
                <h1 class="text-left">{{ title }}</h1>
            </v-col>
            <v-col class="d-flex justify-end align-center text-right">
                <!-- Left Button -->
                <v-btn :to="'/our/2024'" :disabled="currentYear === '2024'" icon class="nav-btn">
                    <v-icon class="nav-icon">mdi-chevron-left</v-icon>
                </v-btn>
                <!-- Right Button -->
                <v-btn :to="'/our/2025'" :disabled="currentYear === '2025'" icon class="nav-btn">
                    <v-icon class="nav-icon">mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { setupWebSocket } from '../api/axios';
import apiClient from '../api/axios';
import WishList from '../components/WishList.vue';
import WordCloud from '../components/WordCloud.vue';

export default {
    components: {
        WishList,
        WordCloud,
    },
    data() {
        return {
            wordList: [],
            selectedWord: null,
            selectedWishes: [],
        };
    },
    props: {
        year: {
            type: String,
            required: true
        }
    },
    computed: {
        field() {
            return this.year === '2024' ? 'text1' : 'text2';
        },
        title() {
            return this.year === '2024' ? '2024 我要谢谢自己...' : '2025 我希望自己...';
        },
        currentYear() {
            return this.year;
        }
    },
    watch: {
        '$route'() {
            this.fetchWordCloud();
        }
    },
    async created() {
        await this.fetchWordCloud();
        this.setupWebSocket();
    },
    methods: {
        async fetchWordCloud() {
            try {
                this.selectedWord = null;
                this.selectedWishes = [];
                const response = await apiClient.post('/word-cloud', { fields: [this.field] });
                this.wordList = response.data;
            } catch (error) {
                console.error("Error fetching word cloud data:", error);
            }
        },
        async handleWordClick(payload) {
            try {
                this.selectedWord = payload.word;
                const response = await apiClient.post('/query-word', payload);
                this.selectedWishes = response.data;
            } catch (error) {
                console.error("Error querying word:", error);
            }
        },
        setupWebSocket() {
            setupWebSocket(() => {
                console.log("New wish received, refreshing word cloud...");
                this.fetchWordCloud(); // or any other action on receiving data
            });
        },
    },
};
</script>

<style scoped>
.page-container {
    padding-top: 150px !important;
}
.v-container {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: none;
    margin: 0;
    width: 100%;
    position: relative;
    /* Necessary for positioning the overlay */
    background-image: url('~@/assets/background.png');
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

.v-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Dark overlay with 50% opacity */
    z-index: 1;
    /* Ensure the overlay is above the background but below the content */
}

.v-container>* {
    position: relative;
    z-index: 2;
    /* Ensure the content is above the overlay */
}

/* Other existing styles */
.page-title {
    max-height: 100px;
    padding-right: 50px;
}

h1 {
    font-size: 3rem;
    font-weight: 500;
    margin-left: 60px;
}

h2 {
    font-size: 1.25rem;
    font-weight: 600;
}

ul {
    padding-left: 20px;
}

li {
    font-size: 1rem;
    margin-bottom: 10px;
}

.v-row {
    flex-grow: 1;
    display: flex;
}

.v-btn {
    margin-left: 10px;
    font-size: 1.5rem;
}
</style>
