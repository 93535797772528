<template>
    <v-container>
        <div ref="wordCloud" class="w-100 h-100"></div>
    </v-container>
</template>

<script>
import * as d3 from 'd3';
import cloud from 'd3-cloud';

export default {
    props: {
        words: Array,  // Expecting an array of objects with "text" and "size" properties
        selectedWord: String,
    },
    mounted() {
        this.renderWordCloud();
    },
    watch: {
        words(newWords) {
            if (newWords) {
                this.renderWordCloud();
            }
        },
    },
    methods: {
        renderWordCloud() {
            const layout = cloud()
                .size([this.$refs.wordCloud.offsetWidth, this.$refs.wordCloud.offsetHeight])
                .words(this.words)
                .padding(2)
                .rotate(() => (Math.random() < 0.5 ? 0 : 90))
                .font('Impact')
                .fontSize(d => d.size)
                .on('end', this.drawCloud);

            layout.start();
        },
        drawCloud(words) {
            d3.select(this.$refs.wordCloud).selectAll('*').remove();  // Clear previous word cloud

            const svg = d3.select(this.$refs.wordCloud).append('svg')
                .attr('width', this.$refs.wordCloud.offsetWidth)
                .attr('height', this.$refs.wordCloud.offsetHeight);

            const group = svg.append('g')
                .attr('transform', `translate(${this.$refs.wordCloud.offsetWidth / 2},${this.$refs.wordCloud.offsetHeight / 2})`);

            // Create the color scale, now using the words themselves in the domain
            const colorScale = d3.scaleOrdinal()
                .domain(words.map(d => d.text))  // Use the word text as the domain
                .range(d3.schemeCategory10);  // Apply color range

            group.selectAll('text')
                .data(words)
                .enter().append('text')
                .style('font-size', d => `${d.size}px`)
                .style('font-family', 'Impact')
                .style('fill', d => colorScale(d.text))
                .style("cursor", "pointer")
                .attr('text-anchor', 'middle')
                .attr('transform', d => `translate(${d.x},${d.y})rotate(${d.rotate})`)
                .text(d => d.text)
                .on("click", (event, d) => {
                    const payload = {
                        word: d.text,
                        fields: [d.field],
                    };
                    this.$emit("word-clicked", payload);
                });
        },
    },
};
</script>

<style scoped>
/* Add any custom styles for the word cloud container */
</style>
