import { createRouter, createWebHashHistory } from 'vue-router';
import WishFormPage from './views/WishFormPage.vue';
import WordCloudPage from './views/WordCloudPage.vue';

const routes = [
    {
        path: '/',
        name: 'WishFormPage',
        component: WishFormPage
    },
    {
        path: '/our/:year',  // Dynamic segment
        name: 'WordCloudPage',
        component: WordCloudPage,
        props: true,  // Enable passing route params as props
        beforeEnter: (to, from, next) => {
            console.log('Navigating to WordCloudPage for year:', to.params.year);
            next();
        }
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.params.year) {
        document.title = `Our ${to.params.year}`; // Dynamically setting the title based on route params
    } else {
        document.title = '2024 OW Offsite'; // Default title
    }
    next();
});

export default router;
