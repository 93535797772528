<template>
    <v-app class="app-background">
        <router-view /> <!-- Render the current route's component -->
    </v-app>
</template>

<script>
export default {};
</script>

<style scoped>
/* Scoped to App.vue */
.app-background {
    background-color: #2c2c2c;
    /* Dark gray */
    color: #ffffff;
    /* White text for contrast */
    min-height: 100vh;
    /* Ensure it spans the entire viewport */
}
</style>