<template>
    <v-container class="wish-form-page">
        <h1 class="title">在年终岁尾之际，留下你的感言</h1>
        <WishForm />
    </v-container>
</template>

<script>
import WishForm from '../components/WishForm.vue';

export default {
    components: {
        WishForm,
    },
};
</script>

<style scoped>
.wish-form-page {
    background-color: #2c2c2c;
    /* Dark gray background */
    color: #ffffff;
    /* White text for contrast */
    height: 100vh;
    /* Full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Center contents both vertically and horizontally */
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 50px;
}</style>
