<template>
    <v-container class="wish-form">
        <v-form @submit.prevent="submitWish" class="form">
            <v-text-field label="你的姓名" v-model="name" outlined class="form-field" required></v-text-field>
            <v-textarea label="2024 我要谢谢自己..." v-model="text1" outlined class="form-field" required></v-textarea>
            <v-textarea label="2025 我希望自己..." v-model="text2" outlined class="form-field" required></v-textarea>
            <v-btn type="submit" color="primary" class="submit-btn">
                提交
            </v-btn>
        </v-form>
        <v-alert v-if="success" type="success" dismissible class="alert">
            提交成功，祝你梦想成真
        </v-alert>
    </v-container>
</template>

<script>
import apiClient from '../api/axios';

export default {
    data() {
        return {
            name: '',
            text1: '',
            text2: '',
            success: false,
        };
    },
    methods: {
        async submitWish() {
            try {
                await apiClient.post('/wishes', {
                    name: this.name,
                    text1: this.text1,
                    text2: this.text2,
                });
                this.success = true;
                this.name = '';
                this.text1 = '';
                this.text2 = '';
                setTimeout(() => (this.success = false), 3000); // Hide alert after 3 seconds
            } catch (error) {
                console.error('Error submitting wish:', error);
            }
        },
    },
};
</script>

<style scoped>
.wish-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Center contents horizontally */
    width: 100%;
    max-width: 600px;
    margin: auto;
    background-color: #333;
    /* Slightly lighter dark gray for contrast */
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form {
    width: 100%;
}

.form-field {
    margin-bottom: 20px;
}

.submit-btn {
    width: 100%;
}

.alert {
    margin-top: 20px;
}
</style>
