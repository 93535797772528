import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// WebSocket client
let wsClient = null;

export const setupWebSocket = (onMessageCallback) => {
    if (!wsClient) {
        const wsUrl = `${process.env.VUE_APP_BACKEND_URL.replace(/^http/, 'ws')}`;
        wsClient = new WebSocket(wsUrl);

        wsClient.onmessage = (message) => {
            const data = JSON.parse(message.data);
            if (data.type === 'new-wish') {
                onMessageCallback();
            }
        };

        wsClient.onopen = () => {
            console.log('WebSocket connected');
        };

        wsClient.onerror = (error) => {
            console.error('WebSocket error', error);
        };

        wsClient.onclose = () => {
            console.log('WebSocket closed');
        };
    }

    return wsClient;
};

export default apiClient;
