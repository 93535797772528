import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import { createVuetify } from 'vuetify';
import { createApp } from 'vue';
import App from './App.vue';

import router from './router';

// Create Vuetify instance
const vuetify = createVuetify({
    components,
    directives,
});

// Create Vue app
createApp(App)
    .use(router)
    .use(vuetify)
    .mount('#app');